<template>
  <div class="glocal-feed-card" :class="{ 'shared-glocal-feed-card': feedObject.content_type == 'share' }">
    <div class="glocal-feed-card__header">
      <div class="glocal-feed-card__header--details">
        <img :src="feedUser?.profile_picture" alt="Image" />
        <div class="name">
          <h4>
            <span>{{ feedUser?.full_name }} </span> 
            <template v-if="feedObject.content_type === 'article'">Shared an article from Glocal Discover.</template>
          </h4>
          <p>{{ feedObject.created_at }}</p>
        </div>
      </div>
      <div class="glocal-feed-card__header--buttons">
        <a-dropdown
          @click.stop
          :trigger="['click']"
          placement="bottomRight"
          class="dropdown"
        >
          <a class="ant-dropdown-link">
            <i class="icon icon-vertical_three-dots"></i>
          </a>
          <template #overlay>
            <a-menu>
              <a-menu-item key="0" v-if="userId == feedObject.user.id">
                <a class="remove-btn" @click="showPostDeleteModal()"
                  >Delete post</a
                >
              </a-menu-item>
              <a-menu-item key="1">
                <a>Copy post link</a>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
    </div>
    <div class="glocal-feed-card__body" :class="{ 'shared-glocal-feed-card__body': feedObject.content_type == 'share' }">
      <div 
        class="glocal-feed-card__body--description"
        :class="{ 'shared-glocal-feed-card__body--description': feedObject.content_type == 'share' }"
        v-if="feedObject.text.length > 0"
      >
        <p>
          {{ feedObject.text }}
        </p>
      </div>
      <template v-if="feedObject.content_type !== 'share'">
        <template class="upload-article" v-if="feedObject.content_type === 'article'">
            <div
              class="upload-article__header"
              @click="openArticle(feedObject.article.id)"
              :style="{
                backgroundImage: `linear-gradient(179.9deg, rgba(0, 0, 0, 0) 0.09%, #000000 90.3%), url(${feedObject.article.image})`,
              }"
            >
              <h2 class="upload-article__header--title">
                {{ feedObject.article.title }}
              </h2>
              <div class="upload-article__header--auth-and-date">
                <img
                  :src="feedObject.article.logo"
                  alt="icon"
                />
                <p>
                  By {{ feedObject.article.authors }}, {{ source }} | Publish date: {{ feedObject.article.pub_date }}
                </p>
              </div>
            </div>
            <div class="upload-article__body">
              <p>
                {{feedObject.article.description}}
              </p>
            </div>
        </template>
        <template v-for="media in feedMedia" :key="media.id">
          <div
            @click="openViewFeedModal(feedObject)"
            class="upload-image"
            v-if="feedContentType === 'image'"
            :style="{
              backgroundImage: `url(${media.file})`,
            }"
          ></div>
          <div v-else-if="feedContentType === 'document'" class="upload-file">
            <iframe :src="media.file"></iframe>
          </div>
          <div class="upload-video" v-else-if="feedContentType === 'video'">
            <video ref="video" loop playsinline type="video/mp4">
              <source :src="media.file" />
            </video>
            <div class="upload-video__play-video-container">
              <button @click="toggleVideo" class="btn">
                <img
                  src="@/assets/icons/pause-icon.png"
                  alt="Play Icon"
                  v-if="isPlaying"
                />
                <img
                  src="@/assets/icons/play-icon.svg"
                  alt="Pause Icon"
                  class="play-icon"
                  v-else
                />
              </button>
            </div>
          </div>
          <div v-else-if="feedContentType === 'audio'" class="upload-voice">
            <div class="upload-voice__audio-player">
              <div class="upload-voice__audio-player--timeline" @click="seek">
                <div class="progress" :style="{ width: progressWidth + '%' }"></div>
              </div>
              <div class="upload-voice__audio-player--controls">
                <div class="play-container" @click="togglePlay">
                  <div :class="isPlaying ? 'toggle-play pause' : 'toggle-play play'"></div>
                </div>
                <div class="time">
                  <div class="current">{{ formatTime(currentTime) }}</div>
                  <div class="divider">/</div>
                  <div class="length">{{ formatTime(duration) }}</div>
                </div>
                <div class="name">
                  <p>{{ audioName }}</p>
                </div>
                <div class="volume-container">
                  <div class="volume-button" @click="toggleMute">
                    <img v-if="isMuted" src="@/assets/icons/volume-mute-icon.png" alt="Mute">
                    <img v-else src="@/assets/icons/volume-icon.png" alt="Full">
                  </div>
                  <div class="volume-slider" @click="changeVolume">
                    <div class="volume-percentage" :style="{ width: volumePercent + '%' }"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </template>
      <template v-else>
        <GlocalRepostCard :feedObject="feedObject.shared" />
      </template>
    </div>
    <the-reaction-badge-counter
      v-if="Object.keys(feedObject.feed_reactions).length > 0"
      :totalArticleReactions="feedObject.feed_reactions"
      :userReactionRepresentation="feedObject.users_reaction_representation"
      :articleId="feedObject.id"
      :feedPage="true"
    >
    </the-reaction-badge-counter>
    <div class="glocal-feed-card__footer">
      <the-reactions :articleId="feedObject.id" :userReaction="feedObject.user_reaction" @updateReaction="updateReaction" ></the-reactions>
      <span class="glocal-feed-card__footer--line"></span>
      <button class="glocal-feed-card__footer--btn">
        <img src="@/assets/icons/bookmark.svg" style="width: 1.4rem;" />
        Save
      </button>
      <span class="glocal-feed-card__footer--line"></span>
      <a-dropdown :trigger="['click']" placement="bottomRight" class="glocal-feed-card__footer--btn">
        <a class="ant-dropdown-link">
          <img src="@/assets/icons/repost-icon.png" style="width: 2.5rem;" />
          Repost
        </a>
        <template #overlay>
          <a-menu>
            <a-menu-item key="0" @click="toggleRepostModal(feedObject)">
              <div class="repost-dropdwon-link">
                <img src="@/assets/icons/edit-icon.svg" alt="Icon" class="repost-dropdwon-link__img repost-dropdwon-link__edit-icon"/>
                <div class="repost-dropdwon-link__text">
                  <h1>Repost with your thoughts</h1>
                  <p>Create a new post with {{ feedObject.content_type === 'share' ? feedObject.shared.user?.full_name : feedUser.full_name }}'s' post attached</p>
                </div>
              </div>
            </a-menu-item>
            <a-menu-item key="1" @click.stop="repostFeed({postId:feedObject.content_type === 'share' ? feedObject.shared.id : feedObject.id, postText:''})">
              <div class="repost-dropdwon-link">
                <img src="@/assets/icons/repost-icon.png" alt="Icon" class="repost-dropdwon-link__img"/>
                <div class="repost-dropdwon-link__text">
                  <h1>Repost</h1>
                  <p>Instantly bring {{ feedObject.content_type === 'share' ? feedObject.shared.user?.full_name : feedUser.full_name }}'s' post to others' feeds</p>
                </div>
              </div>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
      <span class="glocal-feed-card__footer--line"></span>
      <button class="glocal-feed-card__footer--btn">
        <img src="@/assets/icons/reply.svg" style="width: 1.8rem;" />
        Share
      </button>
    </div>
    <!-- </template> -->
  </div>

  <!-- for connection remove -->
  <delete-modal
    :visible="visiblePostDeleteModal"
    @handle-close="visiblePostDeleteModal = false"
    title="Are you sure you want to remove this?"
    confirmText="Yes, Remove"
    cancelText="No, Don't Remove"
    :loading="loader"
    @delete-record="postDelete"
  ></delete-modal>

  <view-feed-modal
    :visible="visibleViewFeedModal"
    @closeViewFeedModal="closeViewFeedModal"
    :viewFeed="viewFeed"
    :feedObject="feedObject"
  >
  </view-feed-modal>
  <repost-modal
    :visible="visibleRepostModal"
    :createPostLoader="createPostLoader"
    @closeViewFeedModal="closeRepostModal"
    :sharedFeed="sharedFeed"
    @rePost="repostFeed"
  >
  </repost-modal>
  <message-toaster 
      :visible="showMessageModal"
      :message="message"
      @handle-close="showMessageModal = false"
      >
  </message-toaster>

</template>

<script>
import { mapActions } from 'vuex';
import DeleteModal from '../BaseComponents/DeleteModal.vue';
import TheReactions from "../BaseComponents/TheReactions.vue";
import TheReactionBadgeCounter from '../BaseComponents/TheReactionBadgeCounter.vue';
import ViewFeedModal from './ViewFeedModal.vue';
import RepostModal from './RepostModal.vue';
import GlocalRepostCard from './GlocalRepostCard.vue';
import MessageToaster from '../BaseComponents/MessageToaster.vue';

export default {
  data() {
    return {
      isPlaying: false,
      visiblePostDeleteModal: false,
      loader: false,
      reRender: 0,
      viewFeed: {},
      visibleViewFeedModal: false,
      audio: null,
      duration: 0,
      currentTime: 0,
      volume: 0.75,
      isMuted: false,
      audioName: "Lorem Ipsum is simply dummy.",
      visibleRepostModal: false,
      sharedFeed:{},
      createPostLoader: false,
      message: {},
      showMessageModal: false,
    };
  },
  props: {
    UploadImage: Boolean,
    UploadVideo: Boolean,
    UploadArticle: Boolean,
    feedObject: Object,
  },
  components: {
    TheReactions,
    DeleteModal,
    TheReactionBadgeCounter,
    ViewFeedModal,
    RepostModal,
    GlocalRepostCard,
    MessageToaster
  },
  computed: {
    feedUser() {
      return this.feedObject?.user;
    },
    feedMedia() {
      return this.feedObject.files;
    },
    feedContentType() {
      return this.feedObject.content_type;
    },
    userId() {
      return this.$store.getters["auth/getUserId"];
    },
    progressWidth() {
      return (this.currentTime / this.duration) * 100;
    },
    volumePercent() {
      return this.volume * 100;
    },
    searchTopic() {
      const topic = this.$store.getters["discover/getSearchedTopic"];
      return  topic === "" ? "Trending-news" : topic.replace(/\s+/g, '-');
    },
  },
  mounted() {
    this.audioFeed()
  },
  methods: {
    ...mapActions("feed", ["fetchFeedList", "updateFeedPageSize", "createFeed"]),
   async shareFeed(feed) {
      const payload = {
        content_type: feed.content_type,
        shared: feed.id
      }
    },
    updateReaction() {
      this.reRender = this.reRender + 1;
    },
    toggleVideo() {
      const video = this.$refs.video;
      if (this.isPlaying) {
        video.pause();
      } else {
        video.play();
      }
      this.isPlaying = !this.isPlaying;
    },
    async postDelete() {
      this.loader = true;
      await this.$store.dispatch("feed/deleteFeed", this.feedObject.id);
      this.updateFeedPageSize(1)
      await this.fetchFeedList();
      this.loader = false;
      this.visiblePostDeleteModal = false;
    },
    showPostDeleteModal() {
      this.visiblePostDeleteModal = true;
    },
    openViewFeedModal(feed) {
      this.viewFeed = feed
      this.visibleViewFeedModal = true
    },
    closeViewFeedModal() {
      this.visibleViewFeedModal = false
    },
    updateDuration() {
      this.duration = this.audio.duration;
    },
    updateCurrentTime() {
      this.currentTime = this.audio.currentTime;
    },
    togglePlay() {
      if (this.audio.paused) {
        this.audio.play();
        this.isPlaying = true;
      } else {
        this.audio.pause();
        this.isPlaying = false;
      }
    },
    toggleMute() {
      this.audio.muted = !this.audio.muted;
      this.isMuted = this.audio.muted;
    },
    changeVolume(event) {
      const sliderWidth = event.currentTarget.offsetWidth;
      const clickPosition = event.offsetX;
      const newVolume = clickPosition / sliderWidth;
      this.audio.volume = newVolume;
      this.volume = newVolume;
    },
    seek(event) {
      const timelineWidth = event.currentTarget.offsetWidth;
      const clickPosition = event.offsetX / timelineWidth;
      this.audio.currentTime = clickPosition * this.duration;
    },
    formatTime(seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = Math.floor(seconds % 60);
      return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
    },
    async openArticle(articleId) {

        this.$emit('articleOpen', true)
        this.$router.push(`/discover/article/${this.searchTopic}/${articleId}`);
        this.$store.commit("discover/SET_ARTICLE_READ_VIEW", true);
    },
    audioFeed() {
      if(this.feedObject.content_type === 'audio') {
        this.feedObject.files.forEach((val)=>{
          this.audio = val.file
        })
        this.audio = new Audio(this.audio);
        this.audio.volume = this.volume;
        this.audio.addEventListener("loadeddata", this.updateDuration);
        this.audio.addEventListener("timeupdate", this.updateCurrentTime);
      }
    },
    toggleRepostModal(feed) {
      this.sharedFeed = feed.content_type !== 'share' ? feed : feed.shared
      this.visibleRepostModal = !this.visibleRepostModal;
    },
    closeRepostModal() {
      this.visibleRepostModal = !this.visibleRepostModal;
      this.shareFeed = {}
    },
    async repostFeed({postId, postText}){
      const payload = {
        content_type: 'share',
        shared: postId,
        text: postText
      }
      try{
        this.createPostLoader = !this.createPostLoader
        await this.createFeed(payload)
        this.updateFeedPageSize(1)
        await this.fetchFeedList();
        this.closeRepostModal()
        this.createPostLoader = !this.createPostLoader
        this.showMessageModal = true;
        this.message = {
          title: "Feed reposted successfully",
          type: "success",
        };
        return
      }
      catch(err){
        this.createPostLoader = !this.createPostLoader
        this.showMessageModal = true;
        this.message = {
          title: "Something went wrong",
          // desc: "Please Try Again later. Sorry for the inconvenience",
          type: "failure",
        };
      }
    }
  },
};
</script>

<style lang="scss">
.glocal-feed-card {
  border: 1px solid $color-dark-grey-5;
  border-radius: 2rem;
  background-color: $color-white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin: 2.4rem 0;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $color-dark-grey-5;
    gap: 1rem;
    padding: 1.8rem 1.5rem;
    &--details {
      display: flex;
      align-items: center;
      gap: 0.9rem;
      img {
        width: 4.4rem;
        height: 4.4rem;
        border-radius: 100%;
        background-color: #ebebeb;
      }
      .name {
        h4 {
          font-size: 1.4rem;
          font-family: $font-primary;
          line-height: 1.8rem;
          margin-bottom: 0;
          color: #676767;
          margin-bottom: 0.4rem;
        }
        span {
          font-size: 1.6rem;
          font-family: $font-primary-bold;
          color: $color-black;
          line-height: 2.1rem;
          margin-bottom: 0;
          margin-right: 0.5rem;
        }
        p {
          font-size: 1.2rem;
          font-family: $font-primary-medium;
          line-height: 1.6rem;
          margin-bottom: 0;
          color: #676767;
        }
      }
      @include respond(phone-x-small) {
        img {
          width: 5rem;
          height: 5rem;
        }
        .name {
          h4 {
            font-size: 1.6rem;
            line-height: 2.2rem;
            margin-bottom: 0;
            span {
              font-size: 2rem;
              line-height: 2.6rem;
            }
          }
          p {
            font-size: 1.6rem;
            line-height: 2.2rem;
          }
        }
      }
    }
    &--buttons {
      display: flex;
      align-items: center;
      gap: 2rem;
      .dropdown {
        .icon {
          color: $color-black;
          font-size: 1.8rem;
        }
        @include respond(phone-x-small) {
          .icon {
            font-size: 2.2rem;
          }
        }
      }
    }
  }
  &__body {
    &--description {
      padding: 1.5rem 1.5rem 1.5rem;
      p {
        font-size: 1.4rem;
        font-family: $font-primary;
        line-height: 2rem;
        margin-bottom: 0;
        color: $color-black;
      }
      @include respond(phone-x-small) {
        p {
          font-size: 2rem;
          line-height: 2.6rem;
        }
      }
    }
    .upload-image {
      width: 100%;
      height: 33rem;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      cursor: pointer;
      @include respond(phone-x-small) {
        height: 38rem;
      }
    }
    .upload-file {
      height: 33rem;
      width: 100%;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      iframe {
        height: 100%;
        width: 100%;
      }
      @include respond(phone-x-small) {
        height: 38rem;
      }
    }
    .upload-video {
      width: 100%;
      height: 38rem;
      position: relative;
      .vsc-controller {
        display: none;
      }
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &__play-video-container {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: #00000066;
        display: flex;
        align-items: center;
        justify-content: center;
        .btn {
          box-shadow: 0px 0px 8px 0px #f8f8f840 inset;
          height: 6.4rem;
          width: 6.4rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border: none;
          outline: none;
          border-radius: 100% !important;
          background-color: #ffffff4b;
          cursor: pointer;
          img {
            width: 1.6rem;
            height: auto;
          }
          .play-icon {
            margin-left: 0.5rem;
          }
        }
      }
      @include respond(phone-x-small) {
        height: 42rem;
      }
    }
    .upload-article {
      &__header {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        height: 38rem;
        padding: 2.6rem 1.5rem;
        background-repeat: no-repeat;
        background-size: cover;
        background-position-x: center;
        background-position-y: center;
        background-color: $color-black;
        cursor: pointer;

        &--title {
          font-size: 2.4rem;
          font-family: $font-primary-medium;
          font-weight: 700;
          line-height: 3.2rem;
          color: $color-white;
          margin-bottom: 1.8rem;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1; /* number of lines to show */
          line-clamp: 1;
          -webkit-box-orient: vertical;
          max-width: 100%;
        }
        &--auth-and-date {
          display: flex;
          align-items: center;
          img {
            width: 3.7rem;
            height: 3.7rem;
            border-radius: 100%;
            margin-right: 1rem;
            object-fit: fill;
            background-color: #d9d9d9;
          }
          p {
            font-size: 1.4rem;
            font-family: $font-primary;
            line-height: 1.7rem;
            color: #b6b6b6;
            margin-bottom: 0;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 5; /* number of lines to show */
            line-clamp: 5;
            -webkit-box-orient: vertical;
          }
        }
      }
      &__body {
        padding: 1.7rem 1.5rem;
        p {
          font-size: 1.6rem;
          font-family: $font-primary;
          line-height: 2.2rem;
          margin-bottom: 0;
          color: $color-black;
          opacity: 0.6;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 5; /* number of lines to show */
          line-clamp: 5;
          -webkit-box-orient: vertical;
        }
      }
    }
    .upload-voice {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4rem 2.6rem;
      &__audio-player {
        height: 6rem;
        width: 90%;
        max-width: 55rem;
        background: $color-white;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        font-family: arial;
        color: white;
        overflow: hidden;
        display: grid;
        grid-template-rows: 0.8rem auto;
        border-radius: 0.5rem;
        border: 1px solid $color-dark-grey-5;
        @include respond(phone-x-small) {
          height: 7rem;
        }
        &--timeline {
          background: #e4e4e4;
          width: 100%;
          position: relative;
          cursor: pointer;
          .progress {
            background: $color-primary;
            width: 0%;
            height: 100%;
            transition: 0.25s;
          }
        }
        &--controls {
          display: flex;
          justify-content: space-between;
          align-items: stretch;
          padding: 0 2rem;

          > * {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .toggle-play {
            &.play {
              cursor: pointer;
              position: relative;
              left: 0;
              height: 0;
              width: 0;
              border: 0.7rem solid #0000;
              border-left: 1.3rem solid rgb(0, 0, 0);
            }
            &.pause {
              height: 1.3rem;
              width: 2rem;
              cursor: pointer;
              position: relative;
              &:before {
                position: absolute;
                top: 0;
                left: 0px;
                background: rgb(0, 0, 0);
                content: "";
                height: 1.3rem;
                width: 0.3rem;
              }
              &:after {
                position: absolute;
                top: 0;
                right: 1rem;
                background: rgb(0, 0, 0);
                content: "";
                height: 1.3rem;
                width: 0.3rem;
              }
            }
          }
          .time {
            display: flex;
            align-items: center;
            > * {
              padding: 0.2rem 0.3rem;
              color: $color-black;
              font-size: 1.2rem;
              line-height: 1.8rem;
              font-family: $font-primary-medium;
              @include respond(phone-x-small) {
                font-size: 1.6rem;
                line-height: 2.2rem;
              }
            }
          }
          .volume-container {
            cursor: pointer;
            .volume-button {
              height: 2.2rem;
              width: 2.2rem;
              display: flex;
              align-items: center;
              img {
                width: 100%;
                height: 100%;
              }
            }
            position: relative;
            z-index: 2;
            .volume-slider {
              position: absolute;
              left: -0.3rem; 
              top: 2.1rem;
              z-index: -1;
              width: 0;
              height: 0.8rem;
              background: white;
              box-shadow: 0 0 20px rgba(0, 0, 0, 0.336);
              transition: .25s;
              border-radius: 0.5rem;
              .volume-percentage {
                background: $color-primary;
                height: 100%;
                width: 75%;
                border-start-start-radius: 0.5rem;
                border-end-start-radius: 0.5rem;
              }
              @include respond(phone-x-small) {
                height: 1rem;
                top: 2.3rem;
              }
            }
            &:hover {
              .volume-slider {
                left: -8.3rem !important;
                width: 8rem !important;
                @include respond(phone-x-small) {
                  left: -10.3rem !important;
                  width: 10rem !important;
                }
              }
            }
          }
          .name {
            p {
              color: $color-black;
              font-size: 1.2rem;
              line-height: 1.8rem;
              font-family: $font-primary-medium;
              margin-bottom: 0;
              white-space: nowrap; 
              width: 16rem; 
              overflow: hidden;
              text-overflow: ellipsis;
              display: block; 
              @include respond(phone-x-small) {
                font-size: 1.6rem;
                line-height: 2.2rem;
              }
            }
          }
        }
      }
    }
  }
  .user-react {
    padding: 1rem 2.6rem;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    margin-top: 0;
    @include respond(phone-x-small) {
      padding: 1.5rem 2.6rem;
      &__outer-flex {
        &--image-conatiner {
          .image {
            height: 2rem;
            width: 2rem;
            margin-left: -0.7rem;
          }
        }
      }
      &__name {
        font-size: 1.6rem;
        line-height: 2.2rem;
      }
    }
  }
  &__footer {
    border-top: 1px solid $color-dark-grey-5;
    display: flex;
    align-items: center;
    width: 100%;
    &--btn {
      width: 33%;
      border: none;
      outline: none;
      font-size: 1.6rem;
      font-family: $font-primary-medium;
      line-height: 2.2rem;
      gap: 1rem;
      color: $color-black !important;
      display: flex;
      align-items: stretch;
      justify-content: center;
      cursor: pointer;
      background-color: transparent;
      padding: 1.8rem 0;
      @include respond(phone-x-small) {
        padding: 2.1rem 0;
      }
      .ant-dropdown-link {
        color: $color-black;
      }
    }
    &--line {
      width: 1px;
      height: 2.3rem;
      background-color: $color-dark-grey-5;
      display: block;
    }
    .the-horizontal-reactions-bar {
      width: 33%;
      padding: 0;
      margin: 0 !important;
      &__reaction-status {
        padding: 1.5rem 0;
        border-end-start-radius: 2rem;
        &--reactions {
          .image {
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 2rem !important;
            }
          }
          .title {
            font-size: 1.6rem;
            font-family: $font-primary-medium;
            line-height: 2.2rem;
          }
        }
      }
    }
  }
}
.ant-dropdown {
  .ant-dropdown-menu  {
    .ant-dropdown-menu-item {
      .ant-dropdown-menu-title-content {
        .repost-dropdwon-link {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 1.5rem 2.2rem;
          gap: 1rem;
          &__img {
            width: 2.4rem;
            height: auto;
          }
          &__edit-icon {
            width: 2rem;
            margin: 0 0.2rem;
          }
          &__text {
            h1 {
              font-size: 1.4rem;
              font-family: $font-primary-medium;
              line-height: 1.6rem;
              margin: 0;
              color: $color-black;
              margin-bottom: 0.3rem;
            }
            p {
              font-size: 1.2rem;
              font-family: $font-primary;
              line-height: 1.4rem;
              margin: 0;
              color: $color-black;
              opacity: 0.6;
            }
          }
        }
      }
    }
  }
}
.shared-glocal-feed-card {
  .glocal-repost-card {
    margin: 0 1.5rem 1.5rem;
    box-shadow: none;
  }
  &__body {
    padding-top: 1.5rem;
    &--description {
      padding-top: 0;
    }
  }
}
</style>
