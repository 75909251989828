<template>
  <div class="glocal-feed-page">
    <mobile-header></mobile-header>
    <the-header whiteColor>
      <template v-slot:right>
        <div data-cy="headerRightNavigation" class="header__right-navigation">
          <navbar-search :engage="false"></navbar-search>
          <!-- <a-tooltip class="link-tooltip">
            <template #title>Glocal AI</template>
            <img
              src="@/assets/icons/glocal-ai-icon-navbar-white.svg"
              alt="icon"
              @click="redirectToGlocalAI()"
              class="glocal-ai-icon"
            />
          </a-tooltip> -->
          <a-tooltip placement="bottom">
            <template #title>
              <h1>Requesting a new source?</h1>
              <p>
                In Glocal you can request more publication sources if you do not
                find them in our research source lists.
              </p>
            </template>
          </a-tooltip>
          <a-button
            class="navigation-btn"
            @click="visibleInviteToGlocalModal()"
          >
            <img
              src="@/assets/icons/forward_to_inbox.svg"
              alt="icon"
              class="contact-icon"
            />
            Invite Contacts
          </a-button>
          <a-tooltip class="link-tooltip">
            <template #title>Alerts</template>
            <div @click="showNotifications()" class="notification">
              <img
                src="@/assets/icons/notifications-icon.svg"
                alt="icon"
                class="navigation-icon bell-icon"
              />
              <span
                v-if="isUserRequest || isNotifications"
                class="notifications-alert"
              ></span>
            </div>
          </a-tooltip>
          <a-tooltip class="link-tooltip">
            <template #title>Archives</template>
            <img src="@/assets/icons/archivebox-white-icon.svg" 
              @click="redirectToArchive()"
              class="navigation-icon archive-icon" 
              width="auto"
              height="auto"
              alt="Archive"
            >
          </a-tooltip>
          <!-- <a-tooltip class="link-tooltip">
            <template #title>Portfolio</template>
            <img
              src="@/assets/icons/portfolio-white.svg"
              alt="icon"
              @click="openPortfolioDrawer()"
              class="navigation-icon"
            />
          </a-tooltip> -->
          <a-tooltip class="link-tooltip">
            <template #title>Network</template>
            <img
              src="@/assets/icons/diversity-icon.png"
              alt="icon"
              @click="openNetworks()"
              class="navigation-icon"
            />
          </a-tooltip>
          <a-tooltip class="link-tooltip profile-dropdown-tootip">
            <a-dropdown :trigger="['click']" placement="bottomRight">
              <a class="ant-dropdown-link">
                <img
                  :src="displayPicture"
                  alt="user"
                  class="navigation-icon icon-account"
                />
              </a>
              <template #overlay>
                <accounts-dropdown></accounts-dropdown>
              </template>
            </a-dropdown>
          </a-tooltip>
        </div>
      </template>
    </the-header>
    <a-row ref="feed-page" id='glocal-feed-page__body' class="glocal-feed-page__body">
      <a-col
        :xs="24"
        :sm="11"
        :md="9"
        :lg="7"
        :xl="7"
        class="glocal-feed-page__body--left-side-bar"
      >
        <GlocalFeedProfileSidebar :skeletonLoader="skeletonLoader"/>
      </a-col>
      <a-col
        :xs="24"
        :sm="13"
        :md="15"
        :lg="10"
        :xl="10"
        class="glocal-feed-page__body--feed"
      >
        <GlocalFeedUpload />
        <div v-if="skeletonLoader">
          <feed-card-skeleton
            v-for="loader in 5"
            :key="loader"
          ></feed-card-skeleton>
        </div>
        <div v-else-if="!skeletonLoader && feeds.length === 0" class="message">
          <p>No feed found</p>
        </div>
        <div v-else>
          <!-- {{feeds}} -->
          <GlocalFeedCard v-for="feed in feeds" :key="feed.id" :feedObject="feed" @articleOpen="articleOpen"/>
          <div v-if="scrollLoading" class="scroll-loader">
            <the-loader></the-loader>
            <p>Loading...</p>
          </div>
        </div>
      </a-col>
      <a-col
        :xs="24"
        :sm="24"
        :md="6"
        :lg="7"
        :xl="7"
        class="glocal-feed-page__body--right-side-bar"
      >
        <GlocalFeedRecommendationsSidebar />
      </a-col>
    </a-row>
  </div>
  <notification-drawer
    :drawer-visibility="showNotificationDrawer"
    @close-drawer="closeNotificationDrawer()"
    :loading="loader"
  ></notification-drawer>
  <portfolio-drawer
    :drawer-visibility="showPortfolioDrawer"
    @close-drawer="closePortfolioDrawer()"
  >
  </portfolio-drawer>
  <invite-to-glocal
    :visibleModal="inviteToGlocalModal"
    @closeModal="closeInviteToGlocalModal"
  >
  </invite-to-glocal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import TheHeader from "../components/layout/ProfilePageHeader.vue";
import NotificationDrawer from "../components/PdbComponents/Notifications.vue";
import AccountsDropdown from "../components/BaseComponents/AccountsDropdown.vue";
import NavbarSearch from "../components/BaseComponents/NavbarSearch.vue";
import InviteToGlocal from "../components/BaseComponents/InviteToGlocal.vue";
import PortfolioDrawer from "../components/PdbComponents/PortfolioDrawer.vue";
import MobileHeader from "../components/layout/MobileHeader.vue";
import GlocalFeedProfileSidebar from "../components/GlocalFeedPageComponents/GlocalFeedProfileSidebar.vue";
import GlocalFeedRecommendationsSidebar from "../components/GlocalFeedPageComponents/GlocalFeedRecommendationsSidebar.vue";
import GlocalFeedUpload from "../components/GlocalFeedPageComponents/GlocalFeedUpload.vue";
import GlocalFeedCard from "../components/GlocalFeedPageComponents/GlocalFeedCard.vue";
import FeedCardSkeleton from "../components/GlocalFeedPageComponents/FeedCardSkeleton.vue";
import TheLoader from "../components/BaseComponents/TheLoader.vue";

export default {
  components: {
    TheHeader,
    NotificationDrawer,
    AccountsDropdown,
    NavbarSearch,
    PortfolioDrawer,
    MobileHeader,
    InviteToGlocal,
    GlocalFeedProfileSidebar,
    GlocalFeedRecommendationsSidebar,
    GlocalFeedUpload,
    GlocalFeedCard,
    FeedCardSkeleton,
    TheLoader
  },
  data() {
    return {
      inviteToGlocalModal: false,
      loader: true,
      showNotificationDrawer: false,
      showPortfolioDrawer: false,
      showTopicDiscussionDrawer: false,
      skeletonLoader: false,
      scrollLoading: false,
      hasMorePages: false
    };
  },
  computed: {
    ...mapGetters({ 
      feeds: "feed/feeds",
      feedScrollHeight:'feed/feedScrollHeight',
      feedPageLimit: 'feed/feedPageLimit',
      hasMoreFeedPages:'feed/hasMoreFeedPages',
      authUserInformation: 'profile/userInformation',
    }),
    userProfile() {
      return this.$store.getters["profile/userInformation"];
    },
    userId() {
      return this.$store.getters["profile/currentLoginUser"];
    },
    isUserRequest() {
      return this.$store.getters["profile/getUserRequestCount"];
    },
    isNotifications() {
      return this.$store.getters["profile/getNotificationCount"];
    },
    displayPicture() {
      return Object.keys(this.userProfile).includes("profile")
        ? this.userProfile.profile.profile_picture
        : "";
    },
  },
  async created(){
    await this.$store.dispatch('profile/getUserInformation')
  },
  async mounted(){
    if (this.feedScrollHeight){
      document.getElementById('glocal-feed-page__body').scrollTo({
        top: this.feedScrollHeight,
        behavior: 'smooth'
      });
      this.setScrollHeight(null)
    } else{
      this.skeletonLoader = true;
      await this.fetchFeedList();    
      this.skeletonLoader = false;
    }
    // Detect when scrolled to bottom.
    const glocalFeedPage = document.querySelector('#glocal-feed-page__body');
    glocalFeedPage.addEventListener('scroll', async () => {
      if (
        glocalFeedPage.scrollTop + glocalFeedPage.clientHeight >= glocalFeedPage.scrollHeight &&
        !this.scrollLoading &&
        this.hasMoreFeedPages
      ) {
        this.scrollLoading = true;
        try {
          this.updateFeedPageSize();
          await this.fetchFeedList();
          this.scrollLoading = false
        } catch (err) {
          if (err.response && err.response.status === 404) {
            console.error(err);
          }
        }
      }
    });
  },
  methods: {
    ...mapActions({
      fetchFeedList: "feed/fetchFeedList",
      setScrollHeight: 'feed/setScrollHeight',
      incrementFeedPageSize: 'feed/incrementFeedPageSize',
      updateFeedPageSize:'feed/updateFeedPageSize',
      setActiveTabKey: 'profile/setActiveTabKey',
      setSelectedUser: 'profile/setSelectedUser'
    }),
    visibleInviteToGlocalModal() {
      this.inviteToGlocalModal = true
    },
    closeInviteToGlocalModal() {
      this.inviteToGlocalModal = false
    },
    showNotifications() {
      this.getFeatures();
      this.showNotificationDrawer = true;
    },
    closeNotificationDrawer() {
      this.showNotificationDrawer = false;
    },
    closeTopicDiscussionDrawer() {
      this.showTopicDiscussionDrawer = false;
    },
    async getFeatures() {
      try {
        const response = await this.$store.dispatch(
          "article/getGlocalFeatures"
        );
        if (response.status === 200) {
          this.loader = false;
        }
      } catch (err) {
        console.log(err);
      }
    },
    redirectToGlocalAI() {
      this.$router.push("/glocal-AI");
    },
    openPortfolioDrawer() {
      this.showPortfolioDrawer = true;
    },
    closePortfolioDrawer() {
      this.showPortfolioDrawer = false;
    },
    openNetworks(){
      this.setActiveTabKey('network')
      this.setSelectedUser({...this.authUserInformation.profile, id:this.authUserInformation.id})
      this.$router.push({
        path: `/user/${this.authUserInformation?.profile?.username}/`,
      });
      this.getUserInformation()
    },
    redirectToArchive() {
      this.setActiveTabKey('archive')
      this.setSelectedUser({...this.authUserInformation.profile, id:this.authUserInformation.id})
      this.$router.push({
        path: `/user/${this.authUserInformation?.profile?.username}/`,
      });
      this.getUserInformation()
    },
    async getUserInformation() {
      try {
        await this.$store.dispatch("profile/fetchUserProfile");
        await this.$store.dispatch("profile/loadExperiences");
        await this.$store.dispatch("profile/getAddedLanguages");
        await this.$store.dispatch("profile/fetchPhotoResume");
        
      } catch (err) {
        console.log(err);
      }
    },
    articleOpen(e){
      const divElements =  document.getElementById('glocal-feed-page__body');
      this.setScrollHeight(divElements.scrollTop)
    },

  },
};
</script>

<style lang="scss">
.glocal-feed-page {
  height: 100vh;
  overflow: hidden;
  background-color: #f7f7f7;
  .profile-page-header-container {
    display: block;
    @include respond(phone-x-small) {
      display: none;
    }
    .header {
      background-color: black !important;

      &__right-navigation {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        z-index: 2;
        width: 100%;
        .navigation-icon {
          color: $color-white;
          margin-left: 3.2rem;
          width: 2.4rem;
          cursor: pointer;
          font-size: 3rem;
          @include respond(phone-large) {
            margin-left: 2rem;
          }
        }
        // .glocal-ai-icon {
        //   width: 4rem;
        //   margin-right: auto;
        //   margin-left: 0.8rem;
        //   cursor: pointer;
        // }
        .notification {
          position: relative;
          margin-left: 3rem;
          width: 1.9rem;
          @include respond(phone-large) {
            margin-left: 2rem;
          }
          .bell-icon {
            width: 1.9rem;
            margin-left: 0;
          }
          .notifications-alert {
            position: absolute;
            content: "";
            width: 0.8rem;
            height: 0.8rem;
            background-color: red;
            border-radius: 100%;
            top: 0.2rem;
            right: 0.2rem;
          }
        }
        .icon-account {
          color: $color-black !important;
          border-radius: 100%;
          border: 0.2rem solid $color-black;
          object-fit: cover;
          font-size: 3rem;
          width: 5.2rem;
          background-color: #ebebeb;
        }
        .archive-icon {
          width: 3rem;
          height: 3rem;
        }
        .navigation-btn {
          color: $color-white;
          font-family: $font-primary-medium;
          font-size: 1.6rem;
          background-color: $color-primary;
          padding: 0 2rem;
          height: 3.8rem;
          margin-left: 2rem;
          line-height: normal;
          border: none;
          outline: none;
          text-align: center;
          @include respond(laptop-small) {
            display: none;
          }

          span {
            line-height: 1.8rem;
          }
          .plus-icon {
            width: 1.4rem;
            height: auto;
            margin-right: 1.4rem;
            margin-bottom: 0.35rem;
          }
          .contact-icon {
            width: 2rem;
            height: auto;
            margin-right: 0.9rem;
          }
        }
        .search-filter {
          display: block;
          &__header {
            .btn {
              display: none;
            }
          }
          @include respond(phone-x-small) {
            display: none;
          }
        }
        .profile-dropdown-tootip {
          img {
            margin-left: 0 !important;
          }
        }
      }
    }
  }
  .mobile-navbar-new {
    display: none;
    @include respond(phone-x-small) {
      display: block;
    }
  }
  &__body {
    margin-top: 8rem;
    height: calc(100vh - 8rem);
    padding: 2.3rem 4rem;
    @include respond(tab-land) {
      padding: 2.3rem 1rem;
    }
    @include respond(phone-x-small) {
      padding: 2.3rem 1.5rem;
    }
    overflow-y: auto;
    @include respond(phone-x-small) {
      margin-top: 11rem;
      height: calc(100vh - 11rem);
    }
    &--left-side-bar,
    &--feed,
    &--right-side-bar {
      position: relative;
      padding: 0 2rem;
      @include respond(tab-land) {
        padding: 0 1rem;
      }
    }
    &--feed {
      .scroll-loader {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: fit-content;
        margin-bottom: 2rem;
        .ant-spin {
          .anticon {
            font-size: 3rem !important;
          }
        }
        p {
          font-size: 1.6rem;
          margin-bottom: 0;
          font-family: $font-primary;
          margin-left: 0.5rem;
        }
      }
      .message {
        p {
          color: $color-black;
          font-size: 1.8rem;
          line-height: 2.4rem;
          font-family: $font-primary;
          text-align: center;
          margin: 5rem 0;
          opacity: 0.6;
        }
      }
    }
    &--right-side-bar {
      @media (max-width: 991px) {
        display: none;
      }
    }
    &--left-side-bar {
      @include respond(phone-x-small) {
        display: none;
      }
    }
  }
}
</style>
