<template>
  <a-modal
    :visible="visible"
    @cancel="closeModal"
    :footer="null"
    class="repost-modal"
    :title="null"
  >
    <div class="repost-modal-container">
      <div class="repost-modal-container__header">
        <div class="repost-modal-container__header--user">
          <div class="image">
            <img
              :src="user?.profile?.profile_picture"
              alt="User profile picture"
            />
          </div>
          <div class="details">
            <h1>{{user?.profile?.full_name}}</h1>
          </div>
        </div>
      </div>
      <div class="repost-modal-container__body">
        <div class="repost-modal-container__body--textarea">
          <textarea rows="1" placeholder="Write your thoughts...." v-model="repostDescription"></textarea>
        </div>
        <GlocalRepostCard :feedObject="sharedFeed"/>
      </div>
      <div class="repost-modal-container__footer">
        <a-button class="discard-btn" @click="closeModal">Discard</a-button>
        <a-button class="post-btn" @click="rePost()" :loading="createPostLoader">Post</a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
import GlocalRepostCard from './GlocalRepostCard.vue';
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      repostDescription: ''
    };
  },
  components: {
    GlocalRepostCard
  },
  props: {
    visible: Boolean,
    sharedFeed:{
      type: Object,
      default: () => ({})
    },
    createPostLoader:{
        type:Boolean,
        default : () => (false)
    }
  },
  computed:{
    ...mapGetters({
      user : 'profile/userInformation'
    })
  },
  watch:{
    sharedFeed:{
      handler(val){
      
        console.log("SHARED FEEd", val,"USER" ,this.user)
      }
    }

  },
  methods: {
    closeModal() {
      this.$emit("closeViewFeedModal", false);
      this.repostDescription = ''
    },
    rePost(){
      this.$emit('rePost',{postId:this.sharedFeed.id , postText:this.repostDescription})
    }
  },
};
</script>

<style lang="scss">
.repost-modal {
  width: 60rem !important;
  top: 8rem;
  .ant-modal-content {
    .ant-modal-close {
      margin-right: 2.1rem !important;
      margin-top: 2rem !important;
      height: fit-content;
      width: fit-content;
      .ant-modal-close-x {
        width: 2rem;
        height: 2rem;
        line-height: 2rem;
        margin-right: 0;
        margin-top: 0;
        .anticon-close {
          svg {
            width: 2rem;
            height: auto;
            color: $color-black;
          }
        }
      }
    }
    .ant-modal-body {
      padding: 0;
      .repost-modal-container {
        &__header {
          display: flex;
          align-items: center;
          padding: 2rem 5rem 2rem 2.6rem;
          &--user {
            display: flex;
            align-items: center;
            gap: 1rem;
            .image {
              width: 4.5rem;
              height: 4.5rem;
              border-radius: 100%;
              overflow: hidden;
              background-color: #ebebeb;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                background-color: #ebebeb;
              }
            }
            .details {
              h1 {
                font-family: $font-primary-medium;
                font-size: 1.6rem;
                color: $color-black;
                line-height: 2rem;
                margin-bottom: 0.2rem;
              }

              p {
                font-family: $font-primary;
                font-size: 1.2rem;
                color: $color-black;
                line-height: 1.6rem;
                margin-bottom: 0;
                opacity: 0.6;
              }
            }
          }
        }
        &__body {
          padding: 1rem 2.6rem;
          max-height: calc(100vh - 30rem);
          overflow-y: auto;
          overflow-x: hidden;
          &::-webkit-scrollbar {
            width: 0.4rem;
            height: 0.4rem;
          }

          &::-webkit-scrollbar-track {
            background-color: transparent;
            border-radius: 0.8rem;
          }

          &::-webkit-scrollbar-thumb {
            background-color: $color-primary;
            border-radius: 0.8rem;
          }
          &--textarea {
            margin-bottom: 1rem;
            textarea {
              padding: 0;
              border: none;
              color: $color-black;
              font-size: 1.6rem;
              font-family: $font-primary;
              height: fit-content;
              width: 100%;
              outline: none;
              border-radius: 5px !important;
              margin-bottom: 0;
              background-color: transparent;
              resize: none;
              display: inline-table;
              &::placeholder {
                color: $color-black;
                opacity: 0.5;
              }
            }
          }
        }
        &__footer {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding: 2rem 2.6rem;
          border-top: 1px solid $color-dark-grey-5;
          .ant-btn {
            width: 10rem;
            border: none;
            outline: none;
            height: 4rem;
            font-size: 1.5rem;
            font-family: $font-primary-medium !important;
            display: block;
            margin-left: 1.5rem;
            padding: 0 !important;

            span {
              line-height: normal;
            }
            .anticon {
              background-color: #ffffff52;
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 0.5rem;
              .anticon-spin {
                font-size: 1.8rem;
              }
            }
          }
          .discard-btn {
            background-color: #e7e7e7;
            color: $color-black;
            .anticon {
              .anticon-spin {
                color: $color-primary;
              }
            }
            &:hover,
            &:focus,
            &:active {
              background-color: #e7e7e7;
              color: $color-black;
            }
          }
          .post-btn {
            background-color: $color-primary;
            color: $color-white;
            .anticon {
              .anticon-spin {
                color: $color-primary;
              }
            }
            &:hover,
            &:focus,
            &:active {
              background-color: $color-primary;
              color: $color-white;
            }
          }
        }
      }
    }
  }
}
</style>
